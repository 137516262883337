import React from 'react'
import './app.css'
import Header from './Components/Header/Header'
import Hero from './Components/Hero/Hero'
import './index.css'
import Companies from './Components/Companies/Companies'
import Residencies from './Components/Residencies/Residencies'
import Value from './Components/Value/Value'
import Contact from './Components/Contact/Contact'
import GetStarted from './Components/GetStarted/GetStarted'
import Footer from './Components/Footer/Footer'

function App() {
  return (
    <div className='Index'>
      <div>
        <div className='white-gradient'/>
        <Header/>
        <Hero/>
      </div>
      <Companies/>
      <Residencies/>
      <Value/>
      <Contact/>
      <GetStarted/>
      <Footer/>
    </div>
    
  )
}

export default App
  